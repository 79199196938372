.container {
    width: 100%;
    height: 60px;
    background: black;
    transition: 0.3s ease all;
    position: absolute;
    z-index: 1;
}

.import-phrase {
    z-index: 10;
    position: absolute;
    left: 15px;
    top: 15px;
    transition: 0.5s ease all;
}

.samwilkins {
    transition: 0.5s ease all;
}

.heading {
    font-size: 24px;
    transition: 0.5s ease all;
    font-family: 'Courier New', Courier, monospace;
}

.menu-items {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 60px;
    color: white;
    padding-top: 16px;
    padding-left: 47px;
}

.page {
    margin-bottom: 15px;
    cursor: pointer;
}

.white-hr {
    width: 100%;
    position: absolute;
    height: 1px;
    background: white;
    top: 60px;
    transition: 0.5s ease all;
}