.outermost {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.5s ease all;
    overflow: hidden;
}

.body {
    transition: 0.5s ease all;
}

.image-container {
    position: absolute;
    width: 75%;
    pointer-events: none;
    top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: calc(100% - 60px);
    background: #EEEEEE;
    transition: 0.5s ease all;
    min-width: 500px;
}

.display {
    align-self: center;
    width: 80%;
    border: 1px solid #00000033;
    box-shadow: 5px 5px 10px #00000033;
}

.unavailable {
    font-family: 'Courier New', Courier, monospace;
    margin-top: 30px;
    font-style: italic;
}

.link-button {
    pointer-events: all;
    position: absolute;
    border: 1px solid black;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
}

.profile-display {
    align-self: center;
    height: 80%;
    border: 1px solid #00000033;
    box-shadow: 5px 5px 10px #00000033;
}

.profile {
    width: 100%;
    flex-direction: row;
}

.links {
    margin-left: 50px;
    font-family: 'Courier New', Courier, monospace;
    max-width: 500px;
    pointer-events: all;
    max-height: 600px;
    overflow: scroll;
}

.meta-span {
    cursor: pointer;
    pointer-events: all;
    margin-top: 10px;
    margin-bottom: 10px;
    color: blue;
}